import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout, mobileAboutDivider, aboutDivider } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileAbout} w="100%" alt="About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px" w="100%">
          <CFImage
            w="100%"
            src={about}
            alt="Naruto Sushi hero text"
            zIndex={98}
          />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
